<template>
  <div class="">
    <div class="">
      <form action="" @submit.prevent="sendInvitation">
        <div class="account-detail">
          <b-row class="mb-3">
            <b-col cols="12" md="5">
              <div class="v16-section-heading">Invite users</div>
              <div class="v16-section-description">Enter email to add a user</div>
            </b-col>
            <b-col cols="12" md="5" class="p-0">
              <div class="v-16-input-control" ref="emailInput">
               <MultiEmail :inviteUsersArrayEmpty="inviteUsersArrayEmpty" @change="(e)=>emailInput=e"></MultiEmail>
              </div>
              <div v-if="users.length<=0" class="v16-section-description">Invite your first user to join the workspace</div>
              <i class="text-danger" v16-section-description>{{ errorText }}</i>
            </b-col>
            <b-col cols="12" md="2"><Button style="min-width: 100%;" :isLoading="isLoadingInvitation"><svg width="20"
                  height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M18.3333 5C18.3333 4.08334 17.5833 3.33334 16.6666 3.33334H3.33329C2.41663 3.33334 1.66663 4.08334 1.66663 5M18.3333 5V15C18.3333 15.9167 17.5833 16.6667 16.6666 16.6667H3.33329C2.41663 16.6667 1.66663 15.9167 1.66663 15V5M18.3333 5L9.99996 10.8333L1.66663 5"
                    stroke="white" stroke-width="1.67" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                Send Invite</Button></b-col>
          </b-row>
        </div>
      </form>
      <div class="account-detail" v-if="users.length">
        <b-row class="mb-3">
          <b-col cols="12" md="5">
            <div class="v16-section-heading">Invited Users</div>
            <div class="v16-section-description">Account users, invited to join the workspace.</div>
          </b-col>
          <b-col cols="12" md="7" class="pl-md-0">
            <div class="card border-radius p-0" style="border-color:var(--border-color); overflow: hidden;">
              <div class="card-body p-0">

                <b-table head-class="text-center" class="leadTable" :stickyColumn="true" hover :responsive="true"
                  :fields="columns" :items="users">


                  <template #cell(name)="data">
                    <div class="d-flex align-items-center gap">
                     
                      <lazy-image v-if="data.item.user?.image" style="height: 40px; object-fit: cover; width:40px; border-radius:50%" :src="data.item.user?.image||'https://images.quizell.com/website/default_start_page.png'"></lazy-image>
                      <div v-else class="text-uppercase d-flex align-items-center justify-content-center"
                        style="height: 40px; display: block; width: 40px; color: white; border-radius:50%;"
                        :style="{ background: randomColors[data.index] }">
                        {{ getUserInitial(data.item) }}
                      </div>
                      <div class="user-name">{{ data.item.user?.name||'No Name' }}</div>
                    </div>

                  </template>
                  <template #cell(email_address)="data">

                    <div class="user-email">{{ data.item.email_address }}</div>

                  </template>
                  <template #cell(status)="data">
                    <div class="d-flex align-items-center">
                      <div class="">
                        <div v-if="data.item.status == 'pending'" class="quizell-badge text-capitalize"
                          style="min-width: 65px;background: rgba(255, 162, 1, 0.15); color:#FFA201">
                          {{ data.item.status }}</div>
                        <div v-else class="quizell-badge text-capitalize quizell-badge-active" style="min-width: 72px;">
                          {{ data.item.status }}</div>
                      </div>
                      <b-spinner small v-if="selectedUser == data.item.id"></b-spinner>
                      <div class="" v-else title="Delete"
                        @click="() => { deleteUserId = data.item.id; $refs.deleteUser.open() }">
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.5 15H14.1667M14.1667 15H27.5M14.1667 15V26.6667C14.1667 27.1087 14.3423 27.5326 14.6548 27.8452C14.9674 28.1577 15.3913 28.3333 15.8333 28.3333H24.1667C24.6087 28.3333 25.0326 28.1577 25.3452 27.8452C25.6577 27.5326 25.8333 27.1087 25.8333 26.6667V15H14.1667ZM16.6667 15V13.3333C16.6667 12.8913 16.8423 12.4674 17.1548 12.1548C17.4674 11.8423 17.8913 11.6667 18.3333 11.6667H21.6667C22.1087 11.6667 22.5326 11.8423 22.8452 12.1548C23.1577 12.4674 23.3333 12.8913 23.3333 13.3333V15M18.3333 19.1667V24.1667M21.6667 19.1667V24.1667"
                            stroke="#667085" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>


                      </div>
                    </div>


                  </template>

                </b-table>
              </div>
            </div>
          </b-col>

        </b-row>
      </div>
    </div>
    <Modal id="delete" size="md" title="<span class='mx-2'>Delete User</span>" ref="deleteUser">
      <div class="mx-2">
        <div class="delteTitle">
          Are you sure want to delete this user?
        </div>
        <div class="deleteSubtext">
          All data will be permanently deleted.
        </div>
        <div class="d-flex justify-content-end mt-3 mb-1">
          <Button :isLoading="selectedUser == deleteUserId" @click="deleteUser()">Delete</Button>
        </div>
      </div>

    </Modal>
  </div>
</template>

<script>
import Modal from '../Layout/Modal.vue';
import Button from '../Layout/Button.vue';
import axios from 'axios';
import MultiEmail from './MultiEmail.vue';
export default {
  components: {
    Button,
    Modal,
    MultiEmail
  },
  data() {
    return {
      inviteUsersArrayEmpty:false,
      deleteUserId: -1,
      errorText: '',
      appUrl: process.env.VUE_APP_BASE_URL,
      emailInput: [],
      isLoadingInvitation: false,
      users: [],
      isLoadingUser: false,
      randomColors: [],
      selectedUser: null,
      columns: [
        {
          key: "name",
          label: "Name",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading-invite text-start",
          thStyle: { width: "40%" },
        },
        {
          key: "email_address",
          label: "Email",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading-invite text-start",
          thStyle: { width: "40%" },
        },
        {
          key: "status",
          label: "Status",
          tdClass: "align-middle data-Table cursor-pointer",
          thClass: "bg-table-heading-invite text-start",
        },


      ],
    };
  },
  methods: {
    async getUsers() {
      this.isLoadingUser = true;
      const response = await this.$store.dispatch('fetchData', {
        axiosMethod: 'get',
        method: 'list',
        resource: 'rolesPermissions',
      });

      if (response && response.status) {
        this.users = response.data || [];
        this.generateRandomDarkColors();
      }

      this.isLoadingUser = false;
    },
    async deleteUser() {
      let id = this.deleteUserId
      this.selectedUser = id
      const response = await this.$store.dispatch('fetchData', {
        axiosMethod: 'delete',
        method: 'delete',
        resource: 'rolesPermissions',
        id: id
      });
      if (response && response.status) {

        this.toast('User Deleted Successfully');
        this.getUsers();
        this.$refs.deleteUser.close();
      }
      else
        this.toast('Something went wrong.')
      this.selectedUser = null
    },
    async sendInvitation() {
      this.errorText = '';
      this.$refs.emailInput.style.borderColor = '#D2D8E0';
      this.isLoadingInvitation = true;
      await axios.post('/invite', { email_address: this.emailInput }).then(response => {
        if (response.status == '200' && response.data.status) {
          this.toast('The invitation has been successfully forwarded.');
          this.inviteUsersArrayEmpty=true;
          this.getUsers();
        }
        else
          this.toast('An unexpected error occurred');
      }).catch(error => {

        if (error.response.data.message) {
          this.toast(error.response.data.message);
          this.errorText = error.response.data.message;
          this.$refs.emailInput.style.borderColor = 'red';
        } else {
          this.toast('An unexpected error occurred');
        }
      })


      this.isLoadingInvitation = false;
      this.inviteUsersArrayEmpty=false;
    },
    toast(text) {
      this.$toasted.show(text, {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },
   
    generateRandomDarkColors() {
      this.randomColors = [];
      const letters = '0123456789ABCDEF';
      const numItems = this.users.length;

      for (let i = 0; i < numItems; i++) {
        let color = '#';

        for (let j = 0; j < 6; j++) {
          const randomIndex = Math.floor(Math.random() * letters.length);
          color += letters[randomIndex];
        }

        this.randomColors.push(color);
      }
    },
    getUserInitial(user) {
      return user.full_name ? user.full_name.charAt(0).toUpperCase() : (user.email_address ? user.email_address.charAt(0).toUpperCase() : "Q");
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
<style scoped>

.user-name,
.user-email {
  color: #1F1F28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 142.857% */
}

.user-email {
  color: #667085 !important;
  ;
}

.delteTitle {
  color: #18191C;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.56px;
  text-transform: capitalize;
}

.deleteSubtext {
  color: #73738D;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  text-align: center;
  font-weight: 400;
  line-height: 100%;
}

.tag-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.child {
  flex: 1 1 auto;
  min-width: 0;
}

.child:first-child {
  flex: 2 1 auto;
  /* First child will take double space */
}

.tag {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 5px 10px;
  border-radius: 5px;
  max-width: fit-content;
}

.close-btn {
  margin-left: 5px;
  cursor: pointer;
}

.input-email {
  border: 1px solid #D2D8E0;
  border-radius: 8px;
}

#emailInput {
  border: none;
  outline: none;
}

.trash-bin {
  background: #e3e3e3;
  border-radius: 8px;
  cursor: pointer;
}

.info {
  font-size: 16px;
  font-style: normal;
  color: #1F1F28;
  font-weight: 500;
}
</style>
<style >
.bg-table-heading-invite{
height: 44px;
padding: 12px 24px;
align-items: center;
gap: 12px;
color: #73738D;
background: rgba(179, 175, 182, 0.07999999821186066);
font-family: Poppins;
font-size: 12px;
font-style: normal;
font-weight: 500;
line-height: 18px; /* 150% */
align-self: stretch;
}
</style>