<template>
    <div class="email-editor w-100" @click="focusInput">
      <div v-for="(email, index) in emails" :key="index" class="email-tag">
        {{ email }}
        <span @click.stop="removeEmail(index)" style="cursor: pointer;"><svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M11.2167 10L15.8333 14.6167V15.8334H14.6167L10 11.2167L5.38333 15.8334H4.16666V14.6167L8.78333 10L4.16666 5.38335V4.16669H5.38333L10 8.78335L14.6167 4.16669H15.8333V5.38335L11.2167 10Z" fill="#B3AFB6"/>
</svg>
</span>
      </div>
      <input
        type="text"
        class="email-input flex-1"
        v-model="emailInput"
        @keydown="checkForTag"
        @blur="checkForTagOnBlur"
        ref="emailInput"
        placeholder="Add email"
      />
    </div>
  </template>
  
  <script>
  import { uniq } from 'lodash';
  export default {
    props:{
      inviteUsersArrayEmpty:Boolean,
    },
    watch:{
      inviteUsersArrayEmpty(newValue){
        if(newValue){
          this.emails=[]
        }
      }
    },
    data() {
      return {
        emails: [],
        emailInput: ''
      };
    },
    methods: {
        isValidEmail(email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailPattern.test(email);
    },
    checkForTagOnBlur(event) {

          event.preventDefault();
          this.addEmailTag();
        
      },
      checkForTag(event) {
        if (event.key === 'Enter' || event.key === ',') {
          event.preventDefault();
          this.addEmailTag();
        }
      },
      addEmailTag() {
        const email = this.emailInput.trim();
        if (this.isValidEmail(email)) {
          this.emails.push(email);
          this.emails= uniq(this.emails)
          this.emailInput = '';
          this.$emit('change',this.emails)
        }
      },
      removeEmail(index) {
        this.emails.splice(index, 1);
      },
      focusInput() {
        this.$refs.emailInput.focus();
      }
    }
  };
  </script>
  
  <style scoped>
  .email-editor {
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }
  .email-tag {
    display: flex;
padding: 2px 4px;
align-items: center;
gap: 8px;
border-radius: 6px;
border: 1px solid #EDEDED;
background: #F6F6F8;
color: #1F1F28;
font-family: Poppins;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 171.429% */
  }
 
  .email-input {
    border: none;
    outline: none;
    font-size: 14px;
    width: 100px;
    flex:1;
  }
  </style>
  